import React from 'react';
import Information from '@/components/organisms/SearchByMansionName/Information';
import SellerFooter from '@/components/atoms/Footer/SellerFooter';
import SellerHeader from '@/components/atoms/Header/SellerHeader';
import { Wrapper } from '@/components/layout/common';
import { Routings } from '@/common/routings';
import { AssessmentFormValue } from '@/models/request/assessment';
import { useDirectAccess } from '@/common/hooks';
import withInitializeApp from '@/utils/hoc/withInitializeApp';

type Props = {
  location: {
    state: {
      assessmentFormValue?: AssessmentFormValue;
    };
  };
};

const InformationPage = (props: Props) => {
  const isDirectAccess = useDirectAccess(Routings.sellerTop.location);
  const isFromSearchMansion = props.location.state?.assessmentFormValue?.isFromSearchMansion;
  const assessment = props.location.state?.assessmentFormValue?.assessment;
  return isDirectAccess ? (
    <></>
  ) : (
    <Wrapper>
      <SellerHeader displayPattern="inquiry" />
      <Information assessmentFormValue={{ assessment: assessment, isFromSearchMansion: isFromSearchMansion }} />
      <SellerFooter />
    </Wrapper>
  );
};

export default withInitializeApp(InformationPage);
