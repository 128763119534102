import theme from '@/styles/theme';
import styled from 'styled-components';

export const SubTitle = styled.h2`
  width: 100%;
  margin-bottom: 20px;
  font: ${theme.fonts.bold_22_33};
  color: #4c586f;
`;

export const MansionDataArea = styled.div`
  margin-bottom: 40px;
  padding: 18px 20px;
  background: #f1f1f1;
  border-radius: 4px;
  width: 100%;
`;

export const MansionData = styled.label`
  font: ${theme.fonts.bold_16_32};
  color: #4c586f;
`;
