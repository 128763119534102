import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Routings } from '@/common/routings';
import { Assessment } from 'openapi/kusabi-frontgw-spec';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormState, schema } from './state';
import { useDispatch, useSelector } from 'react-redux';
import * as assessment from '@/state/modules/assessment';
import {
  ContentWrapper,
  Description,
  EmailCaution,
  Content,
  InnerTd,
  NameArea,
  NameAreaContainer,
  NameTextField,
  NameTextFieldContainer,
  NameTextFieldWrapper,
  Table,
  TBody,
  Td,
  Th,
  Tr,
} from '@/components/atoms/CommonStyles';
import PageTitle from '@/components/atoms/PageTitle';
import FormLabel from '@/components/atoms/FormLabel';
import TextField from '@/components/atoms/TextField';
import Terms from '@/components/atoms/Terms';
import ButtonSet from '@/components/atoms/ButtonSet';
import { MansionData, MansionDataArea, SubTitle } from '../CommonStyles';
import { getPartneridForSessionStorage } from '@/common/utilities';
import { Main } from '@/components/layout/common';
import { AssessmentFormValue } from '@/models/request/assessment';
import ErrorMessage from '@/components/atoms/ErrorMessage';

const FixedDescription = styled(Description)`
  margin-bottom: 20px;
`;

type PersonalInfo = {
  detail: {
    firstName: string;
    firstNameKana: string;
    lastName: string;
    lastNameKana: string;
    mailAddress: string;
    phoneNumber: string;
  };
};

type Props = {
  assessmentFormValue: AssessmentFormValue;
};

const Information = ({ assessmentFormValue }: Props) => {
  const dispatch = useDispatch();
  const assessmentData = assessmentFormValue.assessment;
  const createAssessment = useSelector(assessment.selectors.createAssessment);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormState>({ mode: 'all', resolver: yupResolver(schema) });

  const [assessmentFlg, setAssessmentFlg] = useState(false);
  const [personalInfo, setPersonalInfo] = useState<PersonalInfo | null>(null);

  const mansionName = assessmentData?.mansionInfo?.mansionName;
  const address = `${assessmentData?.addressInfo?.prefectureName}${assessmentData?.addressInfo?.cityName}${assessmentData?.addressInfo?.townName}`;
  const mansionData = mansionName ? `${mansionName}（${address}）` : address;

  const onSubmit: SubmitHandler<FormState> = (data) => {
    const newAssessment: Assessment = {
      ...assessmentData,
      ...data,
      partnerId: getPartneridForSessionStorage(),
    } as Assessment;

    dispatch(assessment.operations.createAssessment(newAssessment));
    setPersonalInfo({
      detail: {
        firstName: data.firstName,
        firstNameKana: data.firstNameKana,
        lastName: data.lastName,
        lastNameKana: data.lastNameKana,
        mailAddress: data.mailAddress,
        phoneNumber: data.phoneNumber,
      },
    });
    setAssessmentFlg(true);
  };

  useEffect(() => {
    if (assessmentFlg) {
      dispatch(assessment.operations.personalInfoValue(personalInfo as PersonalInfo));
      navigate(Routings.result.location, { state: { props: { assessment: createAssessment } } });
    }
  }, [createAssessment]);

  const onClickBackButton = () => {
    if (assessmentFormValue.isFromSearchMansion) {
      navigate(Routings.search.location, { state: { isFromInformation: true } });
    } else {
      navigate(Routings.condition.location, { state: { isFromInformation: true } });
    }
  };

  return (
    <Main>
      <PageTitle>マンションAI査定</PageTitle>

      <ContentWrapper>
        <Content>
          <SubTitle>査定マンション</SubTitle>
          <MansionDataArea>
            <MansionData>{mansionData}</MansionData>
          </MansionDataArea>
          <FixedDescription>ご連絡先をご入力ください　</FixedDescription>
          <Table>
            <TBody>
              <Tr>
                <Th>
                  <FormLabel value="お名前" isRequired />
                </Th>
                <Td>
                  <InnerTd>
                    <NameAreaContainer>
                      <NameArea>
                        <NameTextFieldContainer>
                          <NameTextFieldWrapper>
                            <label htmlFor="lastName">姓</label>
                            <Controller
                              name="lastName"
                              control={control}
                              render={({ field }) => <NameTextField id="lastName" type="text" {...field} />}
                            />
                          </NameTextFieldWrapper>
                          {errors?.lastName && <ErrorMessage>{errors.lastName.message}</ErrorMessage>}
                        </NameTextFieldContainer>

                        <NameTextFieldContainer>
                          <NameTextFieldWrapper>
                            <label htmlFor="firstName">名</label>
                            <Controller
                              name="firstName"
                              control={control}
                              render={({ field }) => <NameTextField id="firstName" type="text" {...field} />}
                            />
                          </NameTextFieldWrapper>
                          {errors?.firstName && <ErrorMessage>{errors.firstName.message}</ErrorMessage>}
                        </NameTextFieldContainer>
                      </NameArea>

                      <NameArea>
                        <NameTextFieldContainer>
                          <NameTextFieldWrapper>
                            <label htmlFor="lastNameKana">せい</label>
                            <Controller
                              name="lastNameKana"
                              control={control}
                              render={({ field }) => <NameTextField id="lastNameKana" type="text" {...field} />}
                            />
                          </NameTextFieldWrapper>
                          {errors?.lastNameKana && <ErrorMessage>{errors.lastNameKana.message}</ErrorMessage>}
                        </NameTextFieldContainer>
                        <NameTextFieldContainer>
                          <NameTextFieldWrapper>
                            <label htmlFor="firstNameKana">めい</label>
                            <Controller
                              name="firstNameKana"
                              control={control}
                              render={({ field }) => <NameTextField id="firstNameKana" type="text" {...field} />}
                            />
                          </NameTextFieldWrapper>
                          {errors?.firstNameKana && <ErrorMessage>{errors.firstNameKana.message}</ErrorMessage>}
                        </NameTextFieldContainer>
                      </NameArea>
                    </NameAreaContainer>
                  </InnerTd>
                </Td>
              </Tr>
              <Tr>
                <Th>
                  <FormLabel value="メールアドレス" htmlFor="mailAddress" isRequired />
                </Th>
                <Td>
                  <InnerTd>
                    <Controller
                      name="mailAddress"
                      control={control}
                      render={({ field }) => <TextField type="text" id="mailAddress" {...field} />}
                    />
                    <EmailCaution>※キャリアメールはご登録いただけません。</EmailCaution>
                    {errors?.mailAddress && <ErrorMessage>{errors.mailAddress.message}</ErrorMessage>}
                  </InnerTd>
                </Td>
              </Tr>
              <Tr>
                <Th>
                  <FormLabel value="電話番号" htmlFor="phoneNumber" isRequired />
                </Th>
                <Td>
                  <InnerTd>
                    <Controller
                      name="phoneNumber"
                      control={control}
                      render={({ field }) => <TextField type="text" id="phoneNumber" {...field} />}
                    />
                    {errors?.phoneNumber && <ErrorMessage>{errors.phoneNumber.message}</ErrorMessage>}
                  </InnerTd>
                </Td>
              </Tr>
            </TBody>
          </Table>
          <Terms />
          <ButtonSet
            withTerms
            mainLabel="AI査定結果を見る"
            onMainClick={handleSubmit(onSubmit)}
            subLabel="前の画面にもどる"
            onSubClick={onClickBackButton}
          />
        </Content>
      </ContentWrapper>
    </Main>
  );
};

export default Information;
