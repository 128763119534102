import * as yup from 'yup';
import { YupErrorMessage } from '@/constants/messages';
import { REGEXP_PHONE, REGEX_HIRAGANA_ZENKAKU } from '@/constants/constants';
import { multiByteCharacterValidator } from '@/utils/multiByteValidator';

export const schema = yup.object({
  lastName: yup.string().trim().required(YupErrorMessage.required).max(100, YupErrorMessage.string.max).label('姓'),
  firstName: yup.string().trim().required(YupErrorMessage.required).max(100, YupErrorMessage.string.max).label('名'),
  lastNameKana: yup
    .string()
    .trim()
    .required(YupErrorMessage.required)
    .max(100, YupErrorMessage.string.max)
    .matches(REGEX_HIRAGANA_ZENKAKU, YupErrorMessage.string.hiragana_zen)
    .label('せい'),
  firstNameKana: yup
    .string()
    .trim()
    .required(YupErrorMessage.required)
    .max(100, YupErrorMessage.string.max)
    .matches(REGEX_HIRAGANA_ZENKAKU, YupErrorMessage.string.hiragana_zen)
    .label('めい'),
  mailAddress: yup
    .string()
    .trim()
    .test(
      'multi_byte_character_not_accepted',
      YupErrorMessage.string.multi_byte_character_not_accepted,
      multiByteCharacterValidator
    )
    .required(YupErrorMessage.required)
    .max(254, YupErrorMessage.string.max)
    .email(YupErrorMessage.string.email)
    .label('メールアドレス'),
  phoneNumber: yup
    .string()
    .trim()
    .max(32, YupErrorMessage.string.max)
    .test('phoneNumber_matches', YupErrorMessage.string.phoneNumber, (value) => {
      if (value) {
        return REGEXP_PHONE.test(value);
      }
      return true;
    })
    .required(YupErrorMessage.required)
    .label('電話番号'),
});

export type FormState = yup.InferType<typeof schema>;
